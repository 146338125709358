import React from "react";
import { useState, useEffect } from "react";

import alertMessage from "../utilities/alertMessage";
import { signin, sendSmsLoginCode } from "../utilities/auth/authApi";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import isIpExcluded from "../../functions/ipLookup";
import PhoneNumberInput from "./phonenumberinput";
import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";
import PasswordWithEye from "../utilities/passwordWithEye";


function Signin(props) {

  // Define variables
  const changeCurrentScreen = props.changeCurrentScreen;
  const runLoginProcess = props.runLoginProcess;
  const updateField = props.updateField;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorText, setErrorText] = useState([]);
  const [loginType, setLoginType] = useState('phone');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');


  // Page load actions
  useEffect(() => {

    // IP lookup
    isIpExcluded().then((response) => {
      if( response ) {
        setLoginType('email');
      }
    }).catch((error) => {
      // Do nothing
    });

  }, []);


  // Define functions
  function loginRouter(event) {

    event.preventDefault();

    // Disable the submit button
    setButtonLoading(true);

    setErrorText('');

    const timezoneOffset = new Date().getTimezoneOffset();

    // Build the payload
    const payload = {
      email: email,
      password: password,
      phone_country_code: countryCode,
      phone_number: phoneNumber,
      type: 'login',
      timezone_offset: timezoneOffset
    };

    if( loginType === 'email') {
      login(payload);
    } else {
      smsLogin(payload);
    }
  }

  function login(payload) {
    if ( email && password ) {

      signin(payload).then((response) => {

        if( response && response.data ){

          const data = response.data;

          if( data.success ) {

            // Send to the dashboard
            let path = '/dashboard';
            runLoginProcess(data, path);

          } else {
            setErrorText(data.errors);
            alertMessage(data.errors, 'error');
            Mixpanel.track('Error: Login Error', {'email': email, error: data.errors});
          }
        } else {
          alertMessage('Sorry, there was an error. Please contact support@flowspeak.io for assistance.', 'error');
          Mixpanel.track('Error: Login Error', {'email': email, error: 'Unknown error'});
        }

      }).catch((error) => {
        setErrorText(error.toString());
        alertMessage(error.toString(), 'error');
        Mixpanel.track('Error: Login Error', {'email': email, error: error.toString()});
      }).finally(() => {
        // Enable the submit button
        setButtonLoading(false);
      });

    } else {
      // Enable the submit button
      setButtonLoading(false);
    }
  }

  function smsLogin(payload) {

    if( !phoneNumber || phoneNumber.length < 4 ) {
      setErrorText('Please enter your phone number');
      setButtonLoading(null)
      return;
    }

    // Set the smsPayload so we can use it later
    updateField('smsPayload', payload);

    sendSmsLoginCode(payload).then((response) => {

      if( response && response.data ) {
        if( response.data.success ) {
          changeCurrentScreen('verify')
        } else {
          setErrorText(response.data.error);
        }
      } else {
        // Handle

      }

    }).catch((error) => {
      // reportError(error);
      setErrorText('Sorry, we had an error. Please contact support@flowspeak.io');
      Mixpanel.track('Error: SMS Login Error', {'phone': phoneNumber, error: error});
    }).finally(() => {
      setTimeout(() => setButtonLoading(null), 400);
    });

  }


  // Screen components
  let loginElement;
  let forgotPasswordElement;

  if( loginType === 'email' ) {

    loginElement = (
      <div>
        <input
          name="email"
          type="email"
          value={email}
          onChange={event => setEmail(event.target.value)}
          minLength="6"
          title="Enter your email address"
          required
          placeholder='Email address'
          class='input'
          maxLength={50}
        />

        <PasswordWithEye
          password={password}
          setPassword={setPassword}
        />

        <div class='secondary-helper-button-container'>
          <button
            type='button'
            class='secondary-helper-button'
            onClick={() => setLoginType('phone')}
          >
            <u>Use phone number instead</u>
          </button>
        </div>

      </div>
    );

    forgotPasswordElement = (
      <div class='secondary-helper-button-container'>
        <a
          href='#forgotpassword'
          class='secondary-helper-button'
          onClick={() => changeCurrentScreen('forgotpassword')}
        >
          Forgot your password?
        </a>
      </div>
    );

  } else {
    loginElement = (
      <div>
        <PhoneNumberInput
          setCountryCode={setCountryCode}
          setPhoneNumber={setPhoneNumber}
          phoneNumber={phoneNumber}
        />

        <div class='secondary-helper-button-container'>
          <button
            type='button'
            class='secondary-helper-button'
            onClick={() => setLoginType('email')}
          >
            <u>Use email & password instead</u>
          </button>
        </div>

      </div>
    );
  }


  return (
    <section class='section-auth-form'>

      <div class='flex-row content-left auth-links-container'>
        <button
          type='button'
          class='clean-button auth-link-button inactive'
          onClick={() => changeCurrentScreen('register')}
        >Sign Up</button>
        <p class='auth-link-button'>Login</p>
      </div>

      <form
        class='signup-login-form'
        onSubmit={loginRouter}
      >

        <div class='input-container'>
          {loginElement}
        </div>

        <div class='form-error-header form-error'>
          {errorText}
        </div>

        <div class='flex-column' style={{marginTop: '15px'}}>
          <ButtonSubmitRound
            value={'Continue'}
            loading={buttonLoading}
          />
        </div>

        {forgotPasswordElement}

      </form>

    </section>
  );
}

export default Signin;