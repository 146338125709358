import axios from 'axios';
import { reportError } from "./sentry";


const transcribeScoreSimple = async (audioBlob, audioFileUri, providedText, messages, extras = null) => {

  let apiUrl = "https://microservice.flowspeak.io/api/v2/transcribe_and_score_simple";

  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //   apiUrl = "http://localhost:8080/api/v2/transcribe_and_score_simple";
  //   // apiUrl = "https://nvl-api-microservice-staging.herokuapp.com/api/v2/transcribe_and_score_simple";
  // }
  const url = encodeURI(apiUrl);

  const formData = new FormData();
  // formData.append('apiKey', "fs000000000003044092320002333");
  const apiKey = "fs000000000003044092320002333";

  formData.append('file', audioBlob, 'audio.wav');

  if( extras ) {
    formData.append('extras', JSON.stringify(extras));
  }


  // Include the last message if possible
  let lastMessage;
  if( messages ) {
    const lastMessageObject = messages[messages.length - 1];
    if( lastMessageObject && lastMessageObject.role === "assistant" ) {
      lastMessage = lastMessageObject.content;
      formData.append('lastMessage', lastMessage);
    }
  }

  const config = {
    method: 'post',
    url: url,
    headers: {
      authorization: `Bearer ${apiKey}`,
      "Content-Type": "multipart/form-data",
     },
    data : formData,
  };

  return axios(config).then(function (response) {
    if( response?.data?.success ) {

      const responseData = response.data;
      responseData.audioFileUri = audioFileUri;

      return responseData;

    } else {
      let errorMessage = 'Sorry, there was an error.';
      errorMessage = 'No speech recognized';
      if( response?.data?.error === 'No speech is detected' ) {
      }

      return { success: false, error: errorMessage}
    }
  }).catch(function (error) {
    reportError(error);
    return { success: false, error: error}
  });

}

export default transcribeScoreSimple;